import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getVariantViewRoutesEnumFromLocation } from '../../utils'
import ExportVariantsModal from './ExportVariantsModal'
import {
  type StateVariantData,
  type StepVariantData,
  type TeamVariantData,
  VariantVisualizationLevelEnum,
} from './types'
import { useDownloadVariantExport } from './useDownloadVariantExport'
import { useGetVariantData } from './useGetVariantData'
import { useSelectedVariantsIndicies } from './useSelectedVariantsIndicies'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ExportProcessVariantsModal = ({ isOpen, onClose }: Props) => {
  const { downloadProcessVariantExportFile } = useDownloadVariantExport()
  const location = useLocation()
  const activeRoute = getVariantViewRoutesEnumFromLocation(location)
  const {
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
  } = useSelectedVariantsIndicies()

  const variantData = useGetVariantData(activeRoute)

  const selectedVariantData = useMemo(() => {
    let selectedVariantIndicies: number[]

    switch (variantData.visualizationLevel) {
      case VariantVisualizationLevelEnum.STEP:
        selectedVariantIndicies = stepLevelVariantIndices
        break

      case VariantVisualizationLevelEnum.STATE:
        selectedVariantIndicies = stateLevelVariantIndices
        break

      case VariantVisualizationLevelEnum.TEAM:
        selectedVariantIndicies = teamLevelVariantIndices
        break

      case VariantVisualizationLevelEnum.WINDOW:
        selectedVariantIndicies = windowLevelVariantIndices
        break

      default:
        selectedVariantIndicies = [0]
        break
    }

    return variantData.allVariantDataArray.filter((_, i) => selectedVariantIndicies.includes(i)) as
      | StepVariantData[]
      | StateVariantData[]
      | TeamVariantData[]
  }, [
    variantData,
    stepLevelVariantIndices,
    stateLevelVariantIndices,
    teamLevelVariantIndices,
    windowLevelVariantIndices,
  ])

  const onExportVariants = async () => {
    await downloadProcessVariantExportFile(selectedVariantData)
  }

  return (
    <ExportVariantsModal
      isOpen={isOpen}
      onClose={onClose}
      selectedVariantData={selectedVariantData}
      onExportVariants={onExportVariants}
    />
  )
}

export default ExportProcessVariantsModal
