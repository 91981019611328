import ExportVariantsModal from '../Processes/ProcessMining/Variants/ExportVariantsModal'
import { useDownloadVariantExport } from '../Processes/ProcessMining/Variants/useDownloadVariantExport'
import { useSelectedVariantsIndicies } from '../Processes/ProcessMining/Variants/useSelectedVariantsIndicies'
import type { TaskVariantData } from './types'

interface Props {
  isOpen: boolean
  onClose: () => void
  taskVariantData: TaskVariantData
}

const ExportTaskVariantsModal = ({ isOpen, onClose, taskVariantData }: Props) => {
  const { downloadTaskVariantExportFile } = useDownloadVariantExport()
  const { windowLevelVariantIndices: selectedVariants } = useSelectedVariantsIndicies()

  const selectedVariantData = taskVariantData.variant_list.filter((_, i) =>
    selectedVariants.includes(i),
  )

  const onExportVariants = async () => {
    await downloadTaskVariantExportFile(selectedVariantData, taskVariantData.nodes)
  }

  return (
    <ExportVariantsModal
      isOpen={isOpen}
      onClose={onClose}
      selectedVariantData={selectedVariantData}
      onExportVariants={onExportVariants}
    />
  )
}

export default ExportTaskVariantsModal
